@import url('https://fonts.googleapis.com/css2?family=Phudu:wght@400;700&family=Figtree:wght@400;700&display=swap');

@font-face {
  font-family: 'Font-Icon';
  src: url(./every_frm_font.ttf) format('truetype');
}

@font-face {
  font-family: 'TekturNarrow';
  src: url(./Tektur/TekturNarrow-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: 'TekturNarrow';
  src: url(./Tektur/TekturNarrow-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}
@font-face {
  font-family: 'TekturNarrow';
  src: url(./Tektur/TekturNarrow-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: 'TekturNarrow';
  src: url(./Tektur/TekturNarrow-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: 'TekturNarrow';
  src: url(./Tektur/TekturNarrow-ExtraBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}
@font-face {
  font-family: 'TekturNarrow';
  src: url(./Tektur/TekturNarrow-Black.ttf) format('truetype');
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-Thin.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-ExtraLight.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(./Sarabun/Sarabun-ExtraBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'Resplandece_Cruzeiro';
  src: url(./Resplandece\ -\ Regular.otf) format('opentype');
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}